

















































import { defineComponent, ref } from "@vue/composition-api";
import { useAuth } from "@/api_connectors/auth";
import RequestSentToast from "@/components/Toast/RequestSentToast.vue";
import { useToast } from "@/composables/useToast";
import FailedRequest from "@/components/Toast/FailedRequest.vue";
import SuccessRequest from "@/components/Toast/SuccessRequest.vue";

export default defineComponent({
    name: "RequestReset",
    components: { SuccessRequest, FailedRequest, RequestSentToast },
    props: {
        welcome: Boolean
    },
    setup() {
        const { sendResetRequest } = useAuth()
        const { showToast } = useToast()
        const email = ref('')

        const resetPW = async (): Promise<void> => {
            const response = await sendResetRequest(email.value);
            if (!response) return showToast('failed-request')
            showToast('success-request')
        }

        return {
            email,
            resetPW
        }
    }
})
